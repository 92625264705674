.blockCenter {
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.flexCenter {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;
}

.width100 {
    width: 100%;
}