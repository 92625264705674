/* @import "bootstrap"; */

:root{
  --theme-color:darkcyan;
  --l:50%;
}

.jamTheme {
  background: var(--theme-color);
  border-color: var(--theme-color);
}

body {
  margin: 0;
  background: #fafafa;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1 {
  text-align: center;
}

#homePageText {
  text-align: center;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.btn-primary {
  --bs-btn-color: var(--bs-white);
  --bs-btn-bg: var(--theme-color);
  --bs-btn-border-color: hsl(var(--theme-color),calc(var(--l) - 7.5%));
  --bs-btn-border-radius: .5rem;
  --bs-btn-hover-color: var(--bs-white);
  --bs-btn-hover-bg: var(--theme-color);
  --bs-btn-hover-border-color: hsl(var(--theme-color),calc(var(--l) - 10%));
  --bs-btn-focus-shadow-rgb: var(--theme-color);
  --bs-btn-active-color: var(--theme-color);
  --bs-btn-active-bg: hsl(var(--theme-color),calc(var(--l) - 20%));
  --bs-btn-active-border-color: hsl(var(--theme-color),calc(var(--l) - 20%));
  --bs-btn-disabled-color: var(--theme-color);
  --bs-btn-disabled-bg: hsl(var(--theme-color),calc(var(--l) - 20%));
  --bs-btn-disabled-border-color: hsl(var(--theme-color),calc(var(--l) - 20%));
}
