.memoCard {
    background-color: cornsilk;
}

.cardBody {
    overflow: hidden;
    -webkit-mask-image: linear-gradient(180deg, #000 60%, transparent);
    mask-image: linear-gradient(180deg, #000 60%, transparent);
}

.memoText {
    white-space: pre-line;
}